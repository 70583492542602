<template>
  <FlotoDropdownPicker
    :value="value"
    :search-fn="searchItem"
    :disabled="disabled"
    :placeholder="placeholder"
    :text-only="textOnly"
    :immediate-search="immediateSearch"
    :transform-options-fn="transformOptions"
    v-bind="$attrs"
    @selected="$emit('selected', $event)"
    @change="$emit('change', $event || unassignedValue)"
  >
    <template v-slot:no-data>
      <h5
        class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
      >
        <MIcon name="search" class="mr-2" />
        {{ $tc('type_to_search') }}
      </h5>
    </template>
    <template v-slot:trigger="{ currentItem }">
      <slot :current-item="currentItem" />
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getContractsApi } from '@modules/contracts/contracts-api'
export default {
  name: 'ContractPicker',
  model: { event: 'change' },
  props: {
    value: { type: [Number, Array], default: undefined },
    placeholder: { type: String, default: undefined },
    textOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    exludedIds: { type: Array, default: undefined },
    exludedParentIds: { type: Array, default: undefined },
    exludedRenewalParentIds: { type: Array, default: undefined },
    immediateSearch: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    this.selectedValueOptions = []
    return {
      isDirty: false,
    }
  },
  methods: {
    transformOptions({ items }) {
      return items.map((m) => ({
        text: `${m.name}: ${m.displayName}`,
        key: m.id,
        vendorId: m.vendorId,
        onlyName: m.displayName,
      }))
    },
    searchItem(name, selectedItems) {
      let selectedItemNames = selectedItems.map(
        (i) => i.onlyName || i.text || i.name || i.displayName || i.label
      )
      if (this.value && !this.isDirty) {
        const ids = Array.isArray(this.value) ? this.value : [this.value]
        return getContractsApi({ ids }, ids.length || 10, 0, {
          archived: true,
        }).then((data) => {
          this.selectedValueOptions = data.items
          this.isDirty = true
          return data
        })
      }
      return getContractsApi(
        {
          displayName: name,
          selectedName: selectedItemNames,
          contractStatus: ['not_started', 'active'],
          exludedParentIds: this.exludedParentIds,
          exludedRenewalParentIds: this.exludedRenewalParentIds,
          exludedIds: this.exludedIds,
        },
        this.immediateSearch ? undefined : 10,
        0
      ).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>
