import {
  transformSolutionForServer,
  transformDiagnosisForServer,
  transformSolution,
  transformDiagnosis,
  transformConversationForServer,
  transformConversation,
  transformWorkUnit,
  transformConclusion,
  transformConclusionForServer,
  transformWorkUnitForServer,
} from '@data/conversation'
import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import { isPortalLogin } from '@utils/auth'

const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

const generateQualification = (moduleName, filters) => {
  let finalQual = {}
  let conversationTypeQual = null
  let additionQual = null
  let searchTermQual = null
  if (filters.conversationType) {
    conversationTypeQual = buildRelationalQualificationStructure(
      `${moduleName}.conversation.conversationType`,
      'equal',
      filters.conversationType,
      'enum'
    )
  }
  if (filters.keyword) {
    searchTermQual = buildRelationalQualificationStructure(
      `${moduleName}.conversation.details`,
      'contains',
      `${filters.keyword}`,
      'string'
    )
  }
  if (filters.conversationType === 'technician_to_requester') {
    additionQual = buildRelationalQualificationStructure(
      `${moduleName}.conversation.conversationType`,
      'equal',
      'requestor_to_technician',
      'enum'
    )
  }
  finalQual = {
    qualDetails: buildFlatQualificationStructure(
      [conversationTypeQual, additionQual, searchTermQual].filter(Boolean)
    ),
  }
  return finalQual
}

export function getWorkApi(moduleName, id, isPublic) {
  return api
    .get(`${isPublic ? '/cportal' : ''}/${moduleName}/${id}/work`)
    .then((data) => {
      return {
        solution: transformSolution(data),
        conclusion: transformConclusion(data),
        diagnosis: transformDiagnosis(data),
        rootCause: transformWorkUnit(data, 'rootCause'),
        symptomps: transformWorkUnit(data, 'symptomps'),
        impact: transformWorkUnit(data, 'impact'),
        workaround: transformWorkUnit(data, 'workaround'),
        backoutPlan: transformWorkUnit(data, 'backoutPlan'),
        rolloutPlan: transformWorkUnit(data, 'rolloutPlan'),
        buildPlan: transformWorkUnit(data, 'buildPlan'),
        testPlan: transformWorkUnit(data, 'testPlan'),
      }
    })
}

export function updateSolutionApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/${id}/work`, transformSolutionForServer(data), {
      message: __t('updated_successfully', { resource: __t('solution') }),
    })
    .then((data) => {
      return {
        solution: transformSolution(data),
        conclusion: transformConclusion(data),
        diagnosis: transformDiagnosis(data),
        rootCause: transformWorkUnit(data, 'rootCause'),
        symptomps: transformWorkUnit(data, 'symptomps'),
        impact: transformWorkUnit(data, 'impact'),
        workaround: transformWorkUnit(data, 'workaround'),
        backoutPlan: transformWorkUnit(data, 'backoutPlan'),
        rolloutPlan: transformWorkUnit(data, 'rolloutPlan'),
      }
    })
}

export function updateConclusionApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/${id}/work`, transformConclusionForServer(data), {
      message: __t('updated_successfully', { resource: __t('conclusion') }),
    })
    .then((data) => {
      return {
        solution: transformSolution(data),
        conclusion: transformConclusion(data),
        diagnosis: transformDiagnosis(data),
        rootCause: transformWorkUnit(data, 'rootCause'),
        symptomps: transformWorkUnit(data, 'symptomps'),
        impact: transformWorkUnit(data, 'impact'),
        workaround: transformWorkUnit(data, 'workaround'),
        backoutPlan: transformWorkUnit(data, 'backoutPlan'),
        rolloutPlan: transformWorkUnit(data, 'rolloutPlan'),
      }
    })
}

export function updateWorkApi(moduleName, id, data, propKey) {
  return api
    .patch(
      `${moduleName}/${id}/work`,
      transformWorkUnitForServer(data, propKey),
      {
        message: __t('updated_successfully', {
          resource: __t(propKey.toLowerCase()),
        }),
      }
    )
    .then((data) => {
      return {
        solution: transformSolution(data),
        conclusion: transformConclusion(data),
        diagnosis: transformDiagnosis(data),
        rootCause: transformWorkUnit(data, 'rootCause'),
        symptomps: transformWorkUnit(data, 'symptomps'),
        impact: transformWorkUnit(data, 'impact'),
        workaround: transformWorkUnit(data, 'workaround'),
        backoutPlan: transformWorkUnit(data, 'backoutPlan'),
        rolloutPlan: transformWorkUnit(data, 'rolloutPlan'),
        buildPlan: transformWorkUnit(data, 'buildPlan'),
        testPlan: transformWorkUnit(data, 'testPlan'),
      }
    })
}

export function updateDiagnosisApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/${id}/work`, transformDiagnosisForServer(data), {
      message: __t('updated_successfully', { resource: __t('diagnosis') }),
    })
    .then((data) => {
      return {
        solution: transformSolution(data),
        conclusion: transformConclusion(data),
        diagnosis: transformDiagnosis(data),
        rootCause: transformWorkUnit(data, 'rootCause'),
        symptomps: transformWorkUnit(data, 'symptomps'),
        impact: transformWorkUnit(data, 'impact'),
        workAround: transformWorkUnit(data, 'workaround'),
        backoutPlan: transformWorkUnit(data, 'backoutPlan'),
        rolloutPlan: transformWorkUnit(data, 'rolloutPlan'),
      }
    })
}

export function addConversationApi(moduleName, id, data) {
  return api
    .post(
      `${isPortalLogin() ? '/cportal' : ''}/${moduleName}/${id}/conversation`,
      transformConversationForServer(data),
      {
        message: __t('added_successfully', { resource: __tc(data.name) }),
      }
    )
    .then((data) => transformConversation(data))
}

export function getConversationsApi(
  moduleName,
  id,
  filters = {},
  limit,
  offset
) {
  const qual = generateQualification(moduleName, filters)
  return api
    .post(
      `${
        isPortalLogin() ? '/cportal' : ''
      }/${moduleName}/${id}/conversation/search/byqual`,
      qual,
      {
        params: {
          size: limit || 1000,
          offset: offset || 0,
          ...(filters.sortBy ? { sort_by: filters.sortBy } : {}),
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformConversation),
        total: data.totalCount,
      }
    })
}

export function updateConversationApi(moduleName, parentId, data) {
  return api
    .patch(
      `${isPortalLogin() ? '/cportal' : ''}/conversation/${data.id}`,
      transformConversationForServer(data),
      {
        message: __t('updated_successfully', { resource: __tc(data.name) }),
      }
    )
    .then((data) => transformConversation(data))
}

export function deleteConversationApi(data) {
  return api.delete(
    `${isPortalLogin() ? '/cportal' : ''}/conversation/${data.id}`,
    {
      message: __t('deleted_successfully', { resource: __tc(data.type) }),
    }
  )
}
