<script>
import { TaskComputed } from '@state/modules/task'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoTaskTypePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    excludedTypes: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    ...TaskComputed,
    currentOptions() {
      let currentList = this.taskTypeOptions || []
      if (this.excludedTypes.length) {
        currentList = currentList.filter(
          (t) =>
            this.excludedTypes.indexOf((t.systemName || '').toLowerCase()) ===
            -1
        )
        return currentList
      } else {
        return currentList
      }
    },
    options() {
      return this.currentOptions
    },
  },
}
</script>
