<script>
import { UserGroupsComputed } from '@state/modules/user-group'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getGroupsApi } from '@modules/user-groups/user-groups-api'
import { buildRelationalQualificationStructure } from '@data/qualification'

export default {
  name: 'FlotoRequesterGroupPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      archivedOptions: [],
    }
  },
  computed: {
    ...UserGroupsComputed,
    options() {
      return this.requesterGroupsOptions.concat(this.archivedOptions)
    },
  },
  created() {
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const options = this.options
      const allAvailableIds = (this.$attrs['additional-options'] || [])
        .concat(options)
        .map((u) => u.key || u.id || u.value)
      const missingItems = v.filter(
        (userId) => allAvailableIds.indexOf(userId) === -1
      )
      if (missingItems.length) {
        this.fetchRequesterGroups(missingItems)
      }
    }
  },
  methods: {
    fetchRequesterGroups(missingItems) {
      getGroupsApi(
        'requester',
        {
          quals: [
            buildRelationalQualificationStructure(
              'id',
              'in',
              missingItems,
              'long',
              'db'
            ),
          ],
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            key: c.id,
            text: c.name,
            disabled: c.archived || c.disabled,
            archived: c.archived,
          }))
        )
      })
    },
  },
}
</script>
