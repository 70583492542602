<template>
  <MPopover
    ref="dropdownPopoverRef"
    :placement="popoverPlacment"
    v-bind="$attrs"
    :disabled="disabled"
    transition-name="slide-up"
    overlay-class-name="picker-overlay reqeuster-email-overlay"
    :overlay-style="{
      opacity: options.length ? 1 : 0,
    }"
    @hide="handleHide"
    @show="handleShow"
    v-on="listeners"
  >
    <template v-slot:trigger>
      <slot
        name="trigger"
        :hide="handleHide"
        :show="handleShow"
        :toggle="toggleDropdown"
      >
        <MInput
          :value="searchText"
          :disabled="disabled"
          :auto-focus="autoFocus"
          :placeholder="placeholder"
          @blur="$emit('blur')"
          @update="handleChange"
        />
      </slot>
    </template>
    <div class="flex flex-col h-100 min-h-0">
      <div ref="scrollContainer" class="flex h-100 min-h-0 flex-col">
        <slot name="before-menu"></slot>
        <FlotoScrollView>
          <slot>
            <MMenu v-if="options.length" ref="menuContainer" :class="menuClass">
              <MMenuItem
                v-for="(item, index) in optionsToDisplay"
                :key="String(item.id || item.value || item.key)"
                :class="{
                  'scroll-dropdown-menu-item': true,
                  [menuItemClass]: true,
                  [menuItemSelectedClass]: index === currentIndex,
                  [menuItemDisabledClass]: item.disabled,
                  'value-active-item': Array.isArray(value)
                    ? value.indexOf(item.key) >= 0
                    : value === item.key,
                }"
              >
                <slot name="menu-item" :item="item" :select-item="selectItem">
                  <div class="text-ellipsis" @click.stop="selectItem(item)">
                    {{ item.text || item.label || item.name }}
                  </div>
                </slot>
              </MMenuItem>
            </MMenu>
            <slot
              v-if="showNoData && optionsToDisplay.length <= 0"
              name="no-data"
            >
              <FlotoNoData
                v-if="showNoData && optionsToDisplay.length <= 0"
                size="small"
              />
            </slot>
          </slot>
        </FlotoScrollView>
        <slot name="after-menu"></slot>
      </div>
    </div>
  </MPopover>
</template>

<script>
import FindIndex from 'lodash/findIndex'
import Mousetrap from 'mousetrap'
import Bus from '@utils/emitter'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'RequesterEmailSelector',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: undefined,
    },
    searchText: {
      type: [String, Number],
      default: undefined,
    },
    placeholder: {
      type: [String],
      default: undefined,
    },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    avoidKeyboardNavigation: { type: Boolean, default: false },
    showNoData: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
    options: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    // @TODO make this classes dynamic somehow
    this.menuClass = 'ant-dropdown-menu'
    this.menuItemClass = 'ant-dropdown-menu-item'
    this.menuItemDisabledClass = 'ant-dropdown-menu-item-disabled'
    this.menuItemSelectedClass = 'ant-dropdown-menu-item-selected'
    let initialIndex
    if (this.avoidKeyboardNavigation) {
      initialIndex = undefined
    } else {
      initialIndex = FindIndex(this.options, { key: this.value })
    }
    return {
      currentIndex: initialIndex === -1 ? 0 : initialIndex,
      isDropdownOpen: false,
    }
  },
  computed: {
    ...authComputed,
    popoverPlacment() {
      return this.isRtl ? 'bottomRight' : 'bottomLeft'
    },
    optionsToDisplay() {
      return this.options
    },
    listeners() {
      const { change, show, hide, ...listeners } = this.$listeners
      return listeners
    },
  },
  created() {
    this.upHandler = this.upHandler.bind(this)
    this.downHandler = this.downHandler.bind(this)
    this.enterHandler = this.enterHandler.bind(this)
    this.keyupHandler = this.keyupHandler.bind(this)
  },
  mounted() {
    if (!this.avoidKeyboardNavigation) {
      this.$once('hook:beforeDestroy', () => {
        this.unbindEvents()
      })
    }
  },
  methods: {
    handleChange(searchText) {
      this.$emit('change', searchText)
      this.$refs.dropdownPopoverRef.show()
      // this.handleShow()
    },
    bindEvents() {
      if (this.avoidKeyboardNavigation) {
        return
      }
      Mousetrap.unbind(['up', 'down', 'enter'])
      document.addEventListener('keyup', this.keyupHandler)
    },
    keyupHandler(e) {
      switch (e.key) {
        case 'ArrowUp':
          this.upHandler(e)
          break
        case 'ArrowDown':
          this.downHandler(e)
          break
        case 'Enter':
          this.enterHandler(e)
          break
        default:
          break
      }
    },
    unbindEvents() {
      if (this.avoidKeyboardNavigation) {
        return
      }
      document.removeEventListener('keyup', this.keyupHandler)
      Mousetrap.bind(['up', 'down', 'enter'], (...args) => {
        const command =
          args[0].key === 'ArrowUp'
            ? 'move_up'
            : args[0].key === 'ArrowDown'
            ? 'move_down'
            : 'enter'
        Bus.$emit(command, ...[...args, command])
      })
    },
    resetCurrentIndex() {
      if (this.avoidKeyboardNavigation) {
        return
      }
      const initialIndex = FindIndex(this.optionsToDisplay, { key: this.value })
      this.currentIndex = initialIndex === -1 ? 0 : initialIndex
      this.$emit('active-item-index-change', this.currentIndex)
    },
    scrollActiveItemToView() {
      if (
        this.$refs.menuContainer &&
        this.$refs.menuContainer.$el.offsetHeight >
          this.$refs.scrollContainer.offsetHeight
      ) {
        const activeMenuItemDom =
          this.$refs.menuContainer.$el.childNodes[this.currentIndex]
        if (activeMenuItemDom) {
          activeMenuItemDom.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    },
    handleHide($event) {
      this.$emit('hide', $event)
      this.unbindEvents()
      if (this.$refs.dropdownPopoverRef) {
        this.$refs.dropdownPopoverRef.hide()
      }
      setTimeout(() => {
        this.isDropdownOpen = false
      }, 350)
    },
    handleShow($event) {
      if (this.disabled) {
        return
      }
      this.resetCurrentIndex()
      this.bindEvents()
      this.$emit('show', $event)
      this.isDropdownOpen = true
    },
    toggleDropdown() {
      if (this.isDropdownOpen) {
        this.handleHide()
      } else {
        this.handleShow()
      }
    },
    upHandler(e) {
      e.stopImmediatePropagation()
      e.preventDefault()
      e.stopPropagation()
      requestAnimationFrame(() => {
        this.currentIndex =
          (this.currentIndex + this.optionsToDisplay.length - 1) %
          this.optionsToDisplay.length
        this.$emit('active-item-index-change', this.currentIndex)
        this.scrollActiveItemToView()
      })
    },
    downHandler(e) {
      e.stopImmediatePropagation()
      e.preventDefault()
      e.stopPropagation()
      requestAnimationFrame(() => {
        this.currentIndex =
          (this.currentIndex + 1) % this.optionsToDisplay.length
        this.$emit('active-item-index-change', this.currentIndex)
        this.scrollActiveItemToView()
      })
      return true
    },
    enterHandler(e) {
      e.stopImmediatePropagation()
      e.stopPropagation()
      e.preventDefault()
      if (this.optionsToDisplay.length) {
        // here the item is selected
        requestAnimationFrame(() => {
          this.selectItem(this.optionsToDisplay[this.currentIndex])
        })
      }
    },
    selectItem(item) {
      this.$emit('select', item)
      this.$emit('blur')
      this.hide()
    },
    show() {
      // if (this.$refs.popoverMenu) {
      //   this.$refs.popoverMenu.show()
      // }
      this.handleShow()
    },
    hide() {
      // if (this.$refs.popoverMenu) {
      //   this.$refs.popoverMenu.hide()
      // }
      this.handleHide()
    },
  },
}
</script>

<style lang="less" scoped>
// .scroll-dropdown-menu-item {
//   color: var(--dropdown-text);
//   &.value-active-item {
//     font-weight: bold;
//     color: var(--dropdown-selected-text);
//     background: var(--dropdown-hover-bg);
//   }
// }
</style>

<style lang="less">
.scroll-dropdown-menu-item {
  a {
    color: inherit;
  }
}
</style>
