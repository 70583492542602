<template>
  <FlotoDropdownPicker
    :disabled="disabled"
    :multiple="multiple"
    :options="options"
    v-bind="$attrs"
    @change="handleChange"
    v-on="listeners"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"> </slot>
    </template>
    <!-- <template v-slot:menu-item="{ item, selectItem }">
      <div
        class="dropdown-item flex items-center"
        href="javascript:;"
        @click.stop="selectItem(item)"
      >
        <ApprovalStatusIcon
          v-if="item.key !== 'null'"
          size="sm"
          :status="item.key"
          show-status
        />
        <template v-else>
          <span class="flex items-center">
            <i class="anticon text-neutral-light"></i>
            <span class="ml-1 text-neutral-light">
              {{ item.text }}
            </span>
          </span>
        </template>
      </div>
    </template> -->
  </FlotoDropdownPicker>
</template>

<script>
import DeepClone from 'lodash/cloneDeep'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
const __tc = getRootPluaralTranslator()
export const ApprovalStatusOptions = () => [
  { id: 'not_requested', key: 'not_requested', text: __tc('not_requested') },
  { id: 'pending', key: 'pending', text: __tc('pending') },
  { id: 'approved', key: 'approved', text: __tc('approved') },
  { id: 'rejected', key: 'rejected', text: __tc('rejected') },
  { id: 'ignored', key: 'ignored', text: __tc('ignored') },
  { id: 'pre_approved', key: 'pre_approved', text: __tc('pre_approved') },
]
export default {
  name: 'FlotoApprovalStatusPicker',
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    excludedStatus: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    options() {
      let currentList = DeepClone(ApprovalStatusOptions())
      if (this.excludedStatus.length) {
        currentList = currentList.filter(
          (o) => this.excludedStatus.indexOf((o.key || '').toLowerCase()) === -1
        )
      }
      return currentList
    },
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
