<script>
import { PreferenceComputed } from '@state/modules/preference'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FeedbackPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    this.selectedEventName = 'feedbackSelected'
    return {}
  },
  computed: {
    ...PreferenceComputed,
    options() {
      const feedbackRatings = this.tenantPrefrences.feedbackRatingValues.value.map(
        (t, index) => ({ text: t, key: index + 1 })
      )
      return feedbackRatings
    },
  },
}
</script>
