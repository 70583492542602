<template>
  <FlotoDrawer :open="showDrawer" @hide="hideDrawer">
    <template v-slot:title>
      {{ $t('insert') }} {{ $t('from') }} {{ $tc('template') }}
    </template>
    <template v-slot:trigger>
      <a
        href="javascript:;"
        class="text-neutral mx-2"
        @click="showDrawer = true"
      >
        <MIcon name="window-restore" class="mx-1" />
        {{ $t('insert') }} {{ $t('from') }} {{ $tc('template') }}
      </a>
    </template>
    <SuggestedResponseTeplate
      :resource="resource"
      @insert="handleInsertTemplate"
    />
    <ResponseTeplateList @selection-change="setSelectedItems" />
    <template v-slot:actions="{ hide }">
      <MButton
        :disabled="selectedItems.length === 0"
        outline
        class="mx-2"
        :loading="processing"
        @click="handleAddTemplate"
      >
        {{ $t('insert') }} {{ $t('from') }} {{ $tc('template') }}
      </MButton>
      <MButton variant="default" @click="hide">
        {{ $t('cancel') }}
      </MButton>
    </template>
  </FlotoDrawer>
</template>

<script>
import ResponseTeplateList from '@components/item-selection-list/response-template-list'
import SuggestedResponseTeplate from './suggested-response-template.vue'
export default {
  name: 'InsertResponseTemplate',
  components: { ResponseTeplateList, SuggestedResponseTeplate },
  props: {
    resource: { type: Object, required: true },
  },
  data() {
    return {
      processing: false,
      showDrawer: false,
      selectedItems: [],
    }
  },
  methods: {
    handleAddTemplate() {
      this.$emit('selected', this.selectedItems[0])
      this.hideDrawer()
    },
    hideDrawer() {
      this.showDrawer = false
    },
    setSelectedItems(items) {
      this.selectedItems = items
    },
    handleInsertTemplate(template) {
      this.$emit('selected', template)
      this.hideDrawer()
    },
  },
}
</script>
