<script>
import DeepClone from 'lodash/cloneDeep'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getRootTranslator } from '@utils/get-module-translator'

const __t = getRootTranslator()
export const SupportLevelOptions = () => [
  { id: 'tier1', key: 'tier1', text: `${__t('tier')} 1` },
  { id: 'tier2', key: 'tier2', text: `${__t('tier')} 2` },
  { id: 'tier3', key: 'tier3', text: `${__t('tier')} 3` },
  { id: 'tier4', key: 'tier4', text: `${__t('tier')} 4` },
]

export default {
  name: 'FlotoSupportLevelPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(SupportLevelOptions())
    },
  },
}
</script>
